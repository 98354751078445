<template>
  <header class="ui-hero-header">
    <div class="container">
      <div class="row">
        <div class="columns c-9 s-6">
          <div class="hero-header-inner">
            <h1 class="ui-title" v-html="$store.state.product.headline"></h1>
            <p
              class="ui-header-description"
              v-html="$store.state.product.text"
            ></p>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeroHeader",
};
</script>

<style></style>
