<template>
  <div class="navbar-language-inner">
    <div class="locale-current">
      <button
        class="locale-current-btn"
        :class="{ active: this.isLangMenuActive }"
        @click="langMenuOpen()"
      >
        <span v-html="this.$i18n.locale"></span>
        <i class="icon-ui-icon-down-open-big"></i>
      </button>
    </div>
    <div
      class="locale-changer-container"
      :class="{ active: this.isLangMenuActive }"
    >
      <div class="locale-changer">
        <div class="locale-changer-inner">
          <button
            type="submit"
            class="locale-change-item"
            v-for="(item, index) in this.$store.state.languages"
            :key="index"
            :class="{ active: this.$i18n.locale == item }"
            @click="
              switchLocale(item);
              langMenuOpen();
            "
          >
            {{ item }}
          </button>

          <!-- <button
            type="submit"
            class="locale-change-item"
            :class="{ active: this.$i18n.locale == 'en' }"
            @click="
              switchLocale('en');
              langMenuOpen();
            "
          >
            EN
          </button>
          <button
            type="submit"
            class="locale-change-item"
            :class="{ active: this.$i18n.locale == 'se' }"
            @click="
              switchLocale('se');
              langMenuOpen();
            "
          >
            SE
          </button>
          <button
            type="submit"
            class="locale-change-item"
            :class="{ active: this.$i18n.locale == 'no' }"
            @click="
              switchLocale('no');
              langMenuOpen();
            "
          >
            NO
          </button>
          <button
            type="submit"
            class="locale-change-item"
            :class="{ active: this.$i18n.locale == 'dk' }"
            @click="
              switchLocale('dk');
              langMenuOpen();
            "
          >
            DK
          </button>
          <button
            type="submit"
            class="locale-change-item"
            :class="{ active: this.$i18n.locale == 'fi' }"
            @click="
              switchLocale('fi');
              langMenuOpen();
            "
          >
            Fi
          </button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LocaleChanger",
  created: () => {},
  mounted() {
    console.log(this.getLanguages());
  },
  methods: {
    langMenuOpen() {
      console.log(this.isLangMenuActive);
      this.isLangMenuActive = !this.isLangMenuActive;
    },
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
        const to = this.$router.resolve({ params: { locale } });
        console.log(to);
        this.$router.push(
          `/${to.params.locale}/${to.params.campaign}/${to.params.product}`
        );
        // this.$router.push(to.fullPath);
      }
    },
    getLanguages() {
      return this.$store.state.languages;
    },
  },
  data() {
    return {
      isLangMenuActive: false,
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(","),
    };
  },
};
</script>

<style lang="sass" scoped></style>
