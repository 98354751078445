<template>
  <nav class="navbar">
    <div class="row">
      <div class="navbar-brand columns c-12">
        <a class="navbar-brand-link">
          <img v-bind:src="$store.state.logo" alt="Logo" />
        </a>
      </div>
      <div class="navbar-language columns c-12">
        <LocaleChanger />
      </div>
    </div>
  </nav>
</template>

<script>
import LocaleChanger from "./LocaleChanger";
export default {
  name: "Navigation",
  components: {
    LocaleChanger,
  },
  data: function() {
    return {
      isActive: false,
      active_el: 0,
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(","),
    };
  },
  methods: {
    menuOpen() {
      this.isActive = !this.isActive;
    },
    currentLang() {
      this.addClass("active");
    },
    activate: function(el) {
      this.active_el = el;
    },
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
        const to = this.$router.resolve({ params: { locale } });
        this.$router.push(to.fullPath);
      }
    },
  },
};
</script>

<style></style>
