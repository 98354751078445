<template>
  <footer class="ui-footer">
    <div class="container">
      <div class="row">
        <div class="columns c-12">
          <img class="logo" v-bind:src="$store.state.logo" alt="Logo" />
        </div>
      </div>
      <div class="row">
        <div class="columns c-12">
          <div class="ui-footer-navbar">
            <h5 class="navbar-title" v-html="$t('footer.menuTitle')"></h5>
            <ul>
              <li>
                <a :href="$t('footer.link1')" v-html="$t('footer.item1')"></a>
              </li>
              <li>
                <a :href="$t('footer.link2')" v-html="$t('footer.item2')"></a>
              </li>
              <li>
                <a :href="$t('footer.link3')" v-html="$t('footer.item3')"></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style></style>
